<template>
  <div>
    <v-dialog v-model="open" max-width="1300" persistent>
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-card-title class="headline mb-4">{{ title }}</v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-md-12">
              <ReadInvoice @setInvoice="setInvoiceRead" v-if="this.getModuleAccess('invoice-scanner','list')"/>

              <!--Datos generales-->
              <DatosGenerales
                :isViewModal="isViewModal"
                :invoice_to_cancel="invoice_to_cancel"
                :invoice="invoice"
                :loading_combos="loading_combos"
                :open="open"
                :validator="$validator"
                :purchase_order="purchase_order != null"
              />

              <!-- Comprobante Asociado -->
              <ComprobanteAsociado
                :invoice="invoice"
                :invoice_to_cancel="invoice_to_cancel"
                :loading_combos="loading_combos"
                :comprobante_asociado="comprobante_asociado"
                :loading_voucher_asociado="loading_voucher_asociado"
                :error_search_cbte_asociado="error_search_cbte_asociado"
                @selectComboId="selectComboId"
                @searchCbteAsociado="searchCbteAsociado"
                :validator="$validator"
              />

              <!--Periodo a imputar-->
              <Periodo :isViewModal="isViewModal" :invoice="invoice" :validator="$validator" />

              <!-- Compra con o sin stock -->
              <ConStock :isViewModal="isViewModal || purchase_order != null" :invoice="invoice" :validator="$validator" />

              <!-- Items Voucher -->
              <ItemsSpent
                :isViewModal="isViewModal"
                :invoice="invoice"
                :open="open"
                :loading_combos="loading_combos"
                @loadItemsFromInvoiceCancel="loadItemsFromInvoiceCancel"
                :validator="$validator"
              />

              <!-- Tributos -->
              <Tributos :isViewModal="isViewModal" :invoice="invoice" :open="open" :loading_combos="loading_combos" @loadItemsFromInvoiceCancel="loadItemsFromInvoiceCancel" :validator="$validator" :purchase_order="purchase_order != null"/>

              <Observation :item="invoice" :isViewModal="isViewModal" subtitle="Ingrese una observación" />

              <v-row>
                <!--Info Extra-->
                <InfoExtra :dialog="open" :item="invoice" :isViewModal="isViewModal" minHeight="264px" />
                <!--Totales-->
                <Totales :isViewModal="isViewModal" :imp_iva="imp_iva" :imp_neto="imp_neto" :imp_tot_conc="imp_tot_conc" :imp_op_ex="imp_op_ex" :imp_total="imp_total" :imp_trib="imp_trib" />
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>

          <v-btn v-if="!isViewModal" color="green darken-1" text @click="generateInvoice" :loading="loading">
            {{ (invoice != null && invoice.id > 0) || invoice.purchase_order === null ? "Editar" : "Generar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogLoading :dialog="dialog_loading" text="Cargando..." />
  </div>
</template>
<script>
import { spentService } from "@/libs/ws/spentService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { billingUtils } from "@/libs/utils/billingUtils";

import { formatUtils } from "@/libs/utils/formatUtils";
import DialogLoading from "@/components/DialogLoading.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";

import DatosGenerales from "@/views/Spents/SpentComponent/DatosGenerales.vue";
import ComprobanteAsociado from "@/views/Spents/SpentComponent/ComprobanteAsociado.vue";
import ItemsSpent from "@/views/Spents/SpentComponent/ItemsSpent.vue";
import Tributos from "@/views/Spents/SpentComponent/Tributos.vue";
import Totales from "@/views/Spents/SpentComponent/Totales.vue";
import ConStock from "@/views/Spents/SpentComponent/ConStock.vue";
import ReadInvoice from "@/views/Spents/SpentComponent/ReadInvoice.vue";

import Periodo from "@/views/Spents/SpentComponent/Periodo.vue";
import InfoExtra from "@/components/Comprobantes/InfoExtra.vue";
import Observation from "@/components/Comprobantes/Observation.vue";
import RolMixin from "../../mixins/RolMixin.vue";

export default {
  components: { ReadInvoice, DialogLoading, DatosGenerales, Periodo, ItemsSpent, Tributos, InfoExtra, Totales, ComprobanteAsociado, Observation, ConStock },
  mixins: [SettingsMixin, RolMixin],
  props: {
    title: String,
    isViewModal: {
      default: false,
      type: Boolean,
    },
    checkbox_label: String,
    //se usa para ver el comprobante
    item: Object,
    open: Boolean,
    invoice_to_cancel: {
      default: null,
      type: Object,
    },

    invoice_to_generate: {
      default: null,
      type: Object,
    },
    formBtn: String,
    closeDialog: Function,
    reload: Function,
    cancel: Function,
    purchase_order: {
      //Se usa para generar comprobante a partir de ordenes de compra
      default: null,
      type: Object,
    },
  },
  data: () => ({
    emmiterService: emmiterService,
    loading: false,
    dialog_loading: false,
    formatUtils: formatUtils,
    checkbox: false,
    ivas: [],
    comprobante_asociado: {
      id: null,
      voucher_number: null,
      pto_vta: null,
      cbte_tipo: null,
    },
    comprobante_asociado_default: null,
    loading_voucher_asociado: false,
    error_search_cbte_asociado: false,
    invoice: {
      id: 0,
      warehouse: null,
      stock_spent: null,
      voucher_number: null,
      dolar_cotiz: null,
      supplier: null,
      receiver: null,
      cbte_tipo: null,
      cbte_fch: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_desde: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_hasta: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      fch_vto_pago: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      concepto: null,
      moneda: null,
      mon_cotiz: 1,
      items_custom: [],
      tributes: [],
      cbtes_asoc: [],
      observation: null,
      is_invoice: false,
      total_paid: 0,
      imp_credit_accumulated: 0,
      purchase_order: null
    },
    //Se separaron de invoice para poder usar el watch sin hacer un bucle infinito
    imp_total: 0,
    imp_neto: 0,
    imp_tot_conc: 0,
    imp_op_ex: 0,
    imp_iva: 0,
    imp_trib: 0,
    defaultInvoice: null,
    loading_combos: {
      cbte_tipos_invoice: false,
      supplier: false,
      receivers: false,
      cbte_tipos: false,
      ptosvtas: false,
      conceptos: false,
      monedas: false,
      ivas: false,
      tributos: false,
    },
  }),
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    open: function (val) {
      if (val) {
        this.init();
      }

      if (this.item && this.item.id != "") {
        this.invoice = JSON.parse(JSON.stringify(this.item));

        if (this.item.cbtes_asoc && this.item.cbtes_asoc.length > 0) {
          this.comprobante_asociado = JSON.parse(JSON.stringify(this.item.cbtes_asoc[0].voucher_asoc));
          this.comprobante_asociado.anulacion = this.item.cbtes_asoc[0].anulacion;
        } else {
          this.comprobante_asociado = JSON.parse(JSON.stringify(this.comprobante_asociado_default));
        }

        this.imp_total = this.item.imp_total;
        this.imp_neto = this.item.imp_neto;
        this.imp_tot_conc = this.item.imp_tot_conc;
        this.imp_op_ex = this.item.imp_op_ex;
        this.imp_iva = this.item.imp_iva;
        this.imp_trib = this.item.imp_trib;
        this.imp_discount = this.item.imp_discount;
        this.invoice.items_custom = this.item.items_custom;
        this.invoice.tributes = this.item.tributes;
      }
      if (this.purchase_order != null) {
        this.imp_total = this.purchase_order.imp_total;
        this.imp_neto = this.purchase_order.imp_neto;
        this.imp_tot_conc = this.purchase_order.imp_tot_conc;
        this.imp_op_ex = this.purchase_order.imp_op_ex;
        this.imp_iva = this.purchase_order.imp_iva;
        this.imp_trib = this.purchase_order.imp_trib;
        this.imp_discount = this.purchase_order.imp_discount;

        this.invoice.purchase_order = this.purchase_order;

        // Cuando abrimos el modal desde purchase_order, hay que eliminar los id para que los cree en spent_item_custom
        if (this.purchase_order.items_custom != undefined) {
          this.purchase_order.items_custom.forEach((element) => {
            element["purchase_order_item_id"] = element.id;
            element.id = null;
            element.quantity = element.quantity_remaining;
          });
        }

        // si setea la misma propiedades de invoice a purchase_order
        for (const propiedad in this.purchase_order) {
          if (Object.prototype.hasOwnProperty.call(this.invoice, propiedad) && propiedad != "id") {
            this.invoice[propiedad] = this.purchase_order[propiedad];
          }
        }
      }
    },
    invoice: {
      handler: function (val) {
        this.calculateTotal();
      },
      deep: true,
    },
    loading_combos: {
      handler: function (val) {
        if (this.isFinishedLoadingCombos()) {
          this.dialog_loading = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultInvoice = JSON.parse(JSON.stringify(this.invoice));
    this.comprobante_asociado_default = JSON.parse(JSON.stringify(this.comprobante_asociado));
  },
  methods: {
    setInvoiceRead(invoice) {
      this.invoice = invoice;
    },
    searchCbteAsociado() {
      this.invoice.cbte_asoc = null; //Reset Asoc
      this.error_search_cbte_asociado = false;

      if (this.comprobante_asociado.voucher_number != null && this.comprobante_asociado.cbte_tipo != null && this.comprobante_asociado.cbte_tipo.Id > 0) {
        if (!this.loading_voucher_asociado) {
          this.loading_voucher_asociado = true;

          spentService
            .getSpentAsoc(this.invoice.supplier.id, this.comprobante_asociado.voucher_number, this.comprobante_asociado.cbte_tipo.Id, this.invoice.receiver.id)
            .then((response) => {
              if (response.status) {
                this.invoice.cbte_asoc = response.result;
              } else {
                this.error_search_cbte_asociado = true;
              }
            })
            .finally(() => {
              this.loading_voucher_asociado = false;
            });
        }
      }
    },
    isFinishedLoadingCombos() {
      return (
        this.loading_combos["cbte_tipos_invoice"] == false &&
        this.loading_combos["supplier"] == false &&
        this.loading_combos["receivers"] == false &&
        this.loading_combos["cbte_tipos"] == false &&
        this.loading_combos["ptosvtas"] == false &&
        this.loading_combos["conceptos"] == false &&
        this.loading_combos["monedas"] == false &&
        this.loading_combos["ivas"] == false &&
        this.loading_combos["tributos"] == false
      );
    },
    init() {
      this.errors.clear();
      this.$validator.reset();
      this.dialog_loading = true;

      if (this.invoice_to_generate != null) {
        this.invoice = JSON.parse(JSON.stringify(this.invoice_to_generate));
      }

      let invoice_sample = this.invoice_to_cancel;
      if (invoice_sample == null) invoice_sample = this.invoice_to_generate;

      if (invoice_sample != null) {
        this.invoice.supplier = invoice_sample.supplier;
        this.invoice.receiver = invoice_sample.receiver;

        //solo si voy envio una factura a cancelar por props
        if (this.invoice_to_cancel != null) {
          this.comprobante_asociado.voucher_number = invoice_sample.voucher_number;
          this.comprobante_asociado.cbte_tipo = invoice_sample.cbte_tipo;
          this.searchCbteAsociado();
          this.loadItemsFromInvoiceCancel();
        }

        this.invoice.cbte_fch = invoice_sample.cbte_fch;
        this.invoice.cbte_fch_serv_desde = invoice_sample.cbte_fch_serv_desde;
        this.invoice.cbte_fch_serv_hasta = invoice_sample.cbte_fch_serv_hasta;
        this.invoice.fch_vto_pago = invoice_sample.fch_vto_pago;
        this.invoice.mon_cotiz = invoice_sample.mon_cotiz;

        if (this.invoice_to_generate != null) {
          //Viene de una integracion
          //Agrego indicadores de la integracion
          this.invoice.purchase_origin = this.invoice_to_generate.purchase_origin;
          this.invoice.external_reference = this.invoice_to_generate.external_reference;

          this.invoice.money_account_id = this.invoice_to_generate.money_account_id;
          this.invoice.charge_cbte_fch = this.invoice_to_generate.charge_cbte_fch;
          this.invoice.spent_cbte_fch = this.invoice_to_generate.spent_cbte_fch;
          this.invoice.spent_imp_total = this.invoice_to_generate.spent_imp_total;

          if (this.invoice_to_generate.tributes == undefined) {
            this.invoice.tributes = [];
          }
        }
      } else {
        this.dialog_loading = false;
      }
    },
    loadItemsFromInvoiceCancel() {
      let items = {};
      let invoice_sample = this.invoice_to_cancel;
      if (invoice_sample == null) invoice_sample = this.invoice_to_generate;

      if (invoice_sample != null) {
        invoice_sample.items_custom.forEach((item) => {
          this.invoice.items_custom.push({
            descripcion: item.descripcion,
            quantity: item.quantity, //No se aumenta la cantidad porque el total va por el precio
            price: item.price,
            sub_cost_center: item.sub_cost_center,
            iva: item.iva,
            total: item.total, //el total lo calcula el frontend con iva
          });
        });
      }
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
    close() {
      this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
      this.$emit("closeDialog");
    },
    customValidate() {
      let valid = true;
      if (this.invoice.warehouse != null && !(this.invoice.warehouse.id > 0)) {
        this.$toast.error("Seleccione un depósito", "Error");
        valid = valid && false;
      }

      this.invoice.items_custom.forEach((item) => {
        if (item.product_presentation != null && !(item.product_presentation.id > 0)) {
          this.$toast.error("Seleccione un producto para ".item.descripcion, "Error");
          valid = valid && false;
        }

        if (item.sub_cost_center != null && !(item.sub_cost_center.id > 0)) {
          this.$toast.error("Seleccione un Subcentro de costo para ".item.descripcion, "Error");
          valid = valid && false;
        }
      });

      return valid;
    },
    generateInvoice() {
      this.$validator.validate().then((valid) => {
        if (valid && this.customValidate()) {
          if (this.comprobante_asociado.cbte_tipo != null && this.invoice.cbte_asoc == null) {
            this.$toast.error("No se encontro el comprobante asociado.", "Error");
            return;
          }

          this.loading = true;
          let data = this.getInvoiceParams();

          if (this.invoice.id > 0) {
            spentService
              .update(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("El Gasto editó correctamente", "OK");
                  this.$emit("reload");
                  this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
                  this.comprobante_asociado = JSON.parse(JSON.stringify(this.comprobante_asociado_default));

                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            spentService
              .create(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("El Gasto generó correctamente", "OK");
                  this.$emit("reload");
                  this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
                  this.comprobante_asociado = JSON.parse(JSON.stringify(this.comprobante_asociado_default));
                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          this.$toast.error("Complete los campos requeridos", "Error");
        }
      });
    },
    getInvoiceParams() {
      let data = {
        id: this.invoice.id,

        warehouse_id: this.invoice.stock_spent ? this.invoice.warehouse.id : null,

        stock_spent: this.invoice.stock_spent,
        voucher_number: this.invoice.voucher_number,
        supplier_id: this.invoice.supplier.id,
        receiver_id: this.invoice.receiver.id,
        cbte_tipo: this.invoice.cbte_tipo.Id,
        cbte_fch: this.invoice.cbte_fch,
        cbte_fch_serv_desde: this.invoice.cbte_fch_serv_desde,
        cbte_fch_serv_hasta: this.invoice.cbte_fch_serv_hasta,
        fch_vto_pago: this.invoice.fch_vto_pago,
        mon_id: this.invoice.moneda.Id,
        mon_cotiz: this.invoice.moneda.Id == "PES" ? 1 : this.invoice.mon_cotiz,
        dolar_cotiz: this.invoice.dolar_cotiz,

        imp_total: parseFloat(this.imp_total).toFixed(2), //Para enviar el mismo
        imp_neto: parseFloat(this.imp_neto).toFixed(2), //Para enviar el mismo
        imp_tot_conc: parseFloat(this.imp_tot_conc).toFixed(2), //Para enviar el mismo
        imp_iva: parseFloat(this.imp_iva).toFixed(2), //Para enviar el mismo
        imp_op_ex: parseFloat(this.imp_op_ex).toFixed(2), //Para enviar el mismo
        imp_trib: parseFloat(this.imp_trib).toFixed(2), //Para enviar el mismo

        items_custom_arr: [],
        tributes_arr: [],
        cbte_asoc: this.invoice.cbte_asoc != null ? this.invoice.cbte_asoc.id : null,
        observation: this.invoice.observation,
        purchase_order: this.invoice.purchase_order != null ? this.invoice.purchase_order.id : null,
      };

      if (this.invoice.shippings != undefined) {
        data.shippings = this.invoice.shippings;
      }

      this.invoice.items_custom.forEach((item) => {
        const item_data = this.calculateCustomeItemTotals(item);
        data.items_custom_arr.push({
          id: item.id,
          desc: item.descripcion,
          iva_id: item.iva != null ? item.iva.Id : null,
          iva_desc: item.iva != null ? item.iva.Desc : "",
          imp_neto: item_data.imp_neto,
          imp_iva: item_data.imp_iva,
          price: item.price,
          sub_cost_center: item.sub_cost_center,
          quantity: item.quantity,
          discount_per: parseFloat(item.discount_per),
          discount_imp: item.discount_imp,
          product_presentation: item.product_presentation != undefined ? item.product_presentation : null,
          purchase_order_item_id: item.purchase_order_item_id,
        });
      });

      this.invoice.tributes.forEach((tribute_item) => {
        data.tributes_arr.push({
          id: tribute_item.id,
          desc: tribute_item.descripcion,
          tribute_id: tribute_item.tribute != null ? tribute_item.tribute.Id : null,
          tribute_desc: tribute_item.tribute != null ? tribute_item.tribute.Desc : "",
          //alic: tribute_item.alic,
          //base_imp: tribute_item.base_imp,
          importe: tribute_item.importe,
        });
      });

      if (this.invoice_to_generate != null) {
        //Viene de una integracion
        //Agrego indicadores de la integracion
        data.purchase_origin = this.invoice_to_generate.purchase_origin;
        data.external_reference = this.invoice_to_generate.external_reference;

        data.charge_cbte_fch = this.invoice_to_generate.charge_cbte_fch;
        data.spent_cbte_fch = this.invoice_to_generate.spent_cbte_fch;
        data.spent_imp_total = this.invoice_to_generate.spent_imp_total;
      }

      return data;
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };

      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price - item.discount_imp);
      const iva_id = item.iva != null ? item.iva.Id : null;
      let imp_iva =
        vouchersService.NoIVAList().includes(iva_id) || iva_id == null ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        discount_imp: item.discount_imp,
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },

    calculateTotal() {
      this.imp_total = 0.0;
      this.imp_neto = 0.0;
      this.imp_tot_conc = 0.0;
      this.imp_iva = 0.0;
      this.imp_op_ex = 0.0;
      this.imp_trib = 0.0;

      this.invoice.items_custom.forEach((item) => {
        //Update discount_imp
        if (item.price != null && item.quantity != null && item.discount_per != null) item.discount_imp = (item.price * item.quantity * item.discount_per) / 100;
        else item.discount_imp = 0;

        let item_totales_obj = this.calculateCustomeItemTotals(item);

        item_totales_obj["iva_id"] = item.iva == null ? null : item.iva.Id;

        let imp_neto = billingUtils.getTotalFor("imp_neto", item_totales_obj);
        let imp_tot_conc = billingUtils.getTotalFor("imp_tot_conc", item_totales_obj);
        let imp_op_ex = billingUtils.getTotalFor("imp_op_ex", item_totales_obj);
        let imp_iva = item_totales_obj.imp_iva;

        this.imp_tot_conc += imp_tot_conc;
        this.imp_iva += imp_iva;
        this.imp_op_ex += imp_op_ex;
        this.imp_neto += imp_neto;
        this.imp_total += imp_neto + imp_tot_conc + imp_op_ex + imp_iva;
      });

      this.invoice.tributes.forEach((tribute) => {
        //const tributo_amount = (tribute.alic / 100.0) * tribute.base_imp;
        const tributo_amount = parseFloat(tribute.importe);
        this.imp_trib += parseFloat(tributo_amount.toFixed(2));
        this.imp_total += parseFloat(tributo_amount.toFixed(2));
      });
    },
  },
};
</script>
